.button-wrapper {
  position: relative;

  & span {
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    transform: translate(50%, -50%);
    cursor: pointer;
  }
}
