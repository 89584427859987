.navbar {
  position: fixed !important;
  // height: 3.5rem;
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  // padding-left: 4rem !important;
  box-shadow: 0px 6px 8px -9px rgba(0, 0, 0, 0.86);
  border-radius: 0 0 4px 4px;
  padding: 0 !important;
  padding-right: 0.5rem !important;
  // padding-left: 3.5rem !important;
  transition: top 0.3s ease-in-out;
}
