.image {
  padding: 0 !important;
  background-image: url(https://source.unsplash.com/random);
  background-repeat: 'no-repeat';
  background-size: cover;
  background-position: center;
}

.paper {
  width: 100%;
  margin: 8rem 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lock-badge {
  padding: 0.5rem 0.7rem;
  border-radius: 50%;
  background-color: #e91e63;
  color: #fff;
}
