.wrapper {
  background-color: #f8f9fd;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & .auth-wrapper {
    width: 35rem;
    min-height: 30rem;
    padding: 3rem;
    padding-top: 0;
    box-shadow: 0px 0px 11px -9px #000000;
    border-radius: 2rem;
    background-color: #ffffff;
    text-align: center;

    & img {
      width: 100%;
      height: 5rem;
      object-fit: contain;
      margin-bottom: 1rem;
    }
  }
}
