@import url('https://fonts.googleapis.com/css2?family=Exo+2&display=swap');

.nav-bar {
  opacity: 1;
  display: flex;
  width: 3.5rem;
  height: 100%;
  position: fixed;
  top: 0;
  transition: top 0.3s ease-in-out;
}

.user-button {
  & button {
    width: 100%;
  }
}

.app-logo {
  font-family: 'Exo 2', sans-serif;
  font-size: 20px;
}

.drawer-content {
  flex-direction: column;
  animation: all 3s ease-in;
  box-shadow: 6px 0px 8px -9px #000000;
}
