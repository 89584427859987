.admin-container {
  width: 100%;
  height: 100%;
  padding: 2rem;

  .admin-nav {
    width: 64rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0px 0px 16px -5px #000000;
    padding: 0.7rem 1rem;
    border-radius: 0.5rem;
  }
}
