.last-visited-wrapper {
  display: flex;
  // width: 100%;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 1.5px 0px rgba(0, 0, 0, 0.75);
  padding: 0.8rem 1.5rem;
  margin: 1rem 1.5rem;
  background-color: #fff;
  gap: 1rem;
}
