.guest-mode-label-wrapper {
  text-align: center;
  border-radius: 0.3rem;
  box-shadow: 0px 0px 1.5px 0px rgba(0, 0, 0, 0.75);
  padding: 0.4rem;
  margin: 1.5rem;
  margin-top: 0;
  background-color: #fff;
  gap: 1rem;

  & a {
    color: inherit;
  }
}
