.wrapper-element {
  padding: 0 25rem;
  width: 100%;
  position: absolute;
  top: 65;
}
.welcome-card {
  --bs-gutter-x: 0 !important;
  width: 35rem !important;
  margin: 0 auto !important;
  margin-bottom: 2rem !important;
  // margin: 0 !important;
}

.portals {
  --bs-gutter-x: 0 !important;
  width: auto;
  padding: 0 3rem !important;
  gap: 1rem;
  justify-content: space-evenly;
}
